import {
    SETMODALLEADS,
    SETSEARCHES,
    SETSEARCHFORMDATA,
    SETSEARCHRESULTS,
    SETSEARCHRESULTSTOTAL
} from "../../ActionType/SearchActionType";

export const setSearches = (searches) => ({
    type: SETSEARCHES,
    searches
})

export const setSearchFormData = (search) => ({
    type: SETSEARCHFORMDATA,
    search
})

export const setSearchResults = (results) => ({
    type: SETSEARCHRESULTS,
    results
})

export const setSearchResultsTotal = (total) => ({
    type: SETSEARCHRESULTSTOTAL,
    total
})

export const setModalLeads = leads => ({
    type: SETMODALLEADS,
    leads
})
