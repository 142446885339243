import {Descriptions, Typography, Radio, Row, Col, Space, Tooltip} from "antd";
import React from "react";
import {useSelector} from "react-redux";
import { LinkOutlined } from '@ant-design/icons'
import {search} from "../../requests";
import {accountSelector} from "../../Store/Selectors/Main";
import LeadsModal from "./LeadsModal";
import {CRM} from "../../constants";

const {Link, Text} = Typography

const Double = (data) => {
    let criteria = []
    const account = useSelector(accountSelector);
    let usersById = {};

    Object.keys(data.entity.criteria).forEach(name => {
        if (!data.entity.criteria[name].length) {
            return;
        }

        let i = 0;

        const criterion = data.entity.criteria[name].map(item => {
            return (
                <Text mark={data.boldItems.includes(item.toLowerCase())} key={item + ++i}>{item}</Text>
            )
        })

        criteria.push((
            <Descriptions.Item contentStyle={{display: 'inline'}} key={data.entity.id + name}>
                {criterion.reduce((prev, curr) => [prev, ', ', curr])}
            </Descriptions.Item>
        ))
    })

    const doubleName = () => {
        if (CRM === 'amo') {
            return (
                <Link  style={{fontWeight: data.merged && data.isMain ? 'bold' : 'normal'}} href={'https://' + account.subdomain + '.amocrm.ru/' + data.entity.entityType + '/detail/' + data.entity.entityId} target="_blank">
                    <LinkOutlined /> {data.entity.name}
                </Link>
            )
        } else if (CRM === 'b24' && data.deleted) {
            return (
                <span>{data.entity.name}</span>
            )
        } else if (CRM === 'b24' && !data.deleted) {
            return (
                <Link  style={{fontWeight: data.merged && data.isMain ? 'bold' : 'normal'}} href={'https://' + account.subdomain + '/crm/contact/details/' + data.entity.entityId + '/'} target="_blank">
                    <LinkOutlined /> {data.entity.name}
                </Link>
            )
        }
    }

    const selectRadio = (e) => {
        search().get('/make-main-double.json', {params: {id: e.target.value}})
    }

    account.users.forEach(user => {
        usersById[user.id] = user;
    })

    const getUser = id => {
        const user = usersById[id]

        if (user) {
            return user
        }

        return {name: 'Удаленный пользователь', id}
    }

    return (
        <>
            <Row>
                <Col span={1}>
                    {!data.merged &&
                        <Radio title="Сделать главным" value={data.id} onChange={selectRadio} />
                    }
                </Col>
                <Col span={23}>
                    <Descriptions column={4}>
                        <Descriptions.Item>
                            <Space direction="vertical">
                                {doubleName()}
                                <div>
                                    <Space>
                                        {getUser(data.entity.responsibleId).name}
                                        <LeadsModal entity={data.entity}/>
                                    </Space>
                                </div>
                                <Space>
                                    <Tooltip
                                        title={'Дата создания: ' + new Date(data.entity.entityCreatedAt * 1000).toLocaleString()}
                                    >
                                        <Text type="secondary">С: {new Date(data.entity.entityCreatedAt * 1000).toLocaleDateString()}</Text>
                                    </Tooltip>
                                    <Tooltip
                                        title={'Дата последего касания: ' + new Date(data.entity.entityUpdatedAt * 1000).toLocaleString()}
                                    >
                                        <Text type="secondary">О: {new Date(data.entity.entityUpdatedAt * 1000).toLocaleDateString()}</Text>
                                    </Tooltip>
                                </Space>
                            </Space>
                        </Descriptions.Item>
                        {criteria}
                    </Descriptions>
                </Col>
            </Row>
        </>
    )
}

export default Double
