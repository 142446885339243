import {
    SETMODALLEADS,
    SETSEARCHES,
    SETSEARCHFORMDATA,
    SETSEARCHRESULTS,
    SETSEARCHRESULTSTOTAL
} from "../../ActionType/SearchActionType";

const initialState = {
    searches: [],
    searchFormData: [],
    results: [],
    totalResults: 0,
    modalLeads: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SETSEARCHES:
            state.searches = action.searches;
            break;
        case SETSEARCHFORMDATA:
            state.searchFormData = action.search;
            break;
        case SETSEARCHRESULTS:
            state.results = action.results;
            break;
        case SETSEARCHRESULTSTOTAL:
            state.totalResults = action.total;
            break;
        case SETMODALLEADS:
            state.modalLeads = action.leads;
            break;
        default:
            break;
    }

    return state;
}

export default reducer;
