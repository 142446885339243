import { search } from '../../../../requests'
import {setError, setSuccess} from "../../MainAction";
import {setModalLeads, setSearches, setSearchFormData, setSearchResults, setSearchResultsTotal} from "../index";
import {BASE_URL, CRM, URL_HASH} from "../../../../constants";

export const createSearch = (data) => (dispatch) => {
    search().post('/edit.json', data).then(res => {
        dispatch(setSuccess('Сохранено!'));
        window.location.href = BASE_URL + '/' + URL_HASH;
    }).catch(err => {
        dispatch(setError(err.response.data.data.message, err.response))
    });
}

export const getSearches = () => (dispatch) => {
    search().get('/index.json').then(res => {
        dispatch(setSearches(res.data.data));
    }).catch(err => {
        dispatch(setError(err.response.data.data.message, err.response))
    });
}

export const getSearchFormData = (id) => (dispatch) => {
    search().get('/get.json', {params: {id}}).then(res => {
        dispatch(setSearchFormData(res.data.data));
    }).catch(err => {
        dispatch(setError(err.response.data.data.message, err.response))
    });
}

export const getSearchResults = (id, page, perPage, merged, onlyWithLeads) => (dispatch) => {
    search().get('/results.json', {params: {id, page, perPage, merged, onlyWithLeads}}).then(res => {
        dispatch(setSearchResults(res.data.data.items))
        dispatch(setSearchResultsTotal(res.data.data.total))
    }).catch(err => {
        dispatch(setError(err.response.data.data.message, err.response))
    });
}

export const getLinkedLeads = entityId => dispatch => {
    search().get('/linked-leads.json', {params: {entityId, crm: CRM}}).then(res => {
        dispatch(setModalLeads(res.data.data));
    }).catch(err => {
        dispatch(setError(err.response.data.data.message, err.response))
    });
}
