const getHash = () => {
    let hash = null,
        crm = null;

    window.location.pathname.split('/').forEach(item => {
        if (item.startsWith('hash-')) {
            const parts = item.split('-')

            hash = parts[1];
            crm = parts[2];
        }
    })

    return [hash, crm];
}

const data = getHash();

export const HASH = data[0];
export const CRM = data[1] !== undefined ? data[1] : 'amo';
export const URL_HASH = 'hash-' + HASH + '-' + CRM;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
