import {SETERROR, SETSUCCESS, SETINFO, SETWARNING, SETACCOUNT, SETFIELDS} from "../../ActionType/MainActionType";

export const setError = (text, error) => ({
    type: SETERROR,
    text,
    error
})

export const setInfo = (text) => ({
    type: SETINFO,
    text
})

export const setSuccess = (text) => ({
    type: SETSUCCESS,
    text
})

export const setWarning = (text) => ({
    type: SETWARNING,
    text
})

export const setAccount = (data) => ({
    type: SETACCOUNT,
    data
})

export const setFields = (data) => ({
    type: SETFIELDS,
    data
})
