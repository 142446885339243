import axios from 'axios';
import {HASH} from "../constants";

export const search = () => {
    return axios.create({baseURL: `https://goodb.ru/api/v1/doubles/search`, params: {hash: HASH}});
};

export const api = () => {
    return axios.create({baseURL: `https://goodb.ru/api/v1/doubles/api`, params: {hash: HASH}});
};
