import {api} from "../../../../requests";
import {setAccount, setError, setFields} from "../index";
import {CRM} from "../../../../constants";

export const getAccount = () => (dispatch) => {
    api().get('/account-data.json', {params: {crm: CRM}}).then(res => {
        dispatch(setAccount(res.data.data))
    }).catch(err => {
        dispatch(setError(err.response.data.data.message, err.response))
    });
}

export const getFields = entityType => dispatch => {
    api().get('/fields.json', {params: {entityType, crm: CRM}}).then(res => {
        dispatch(setFields(res.data.data))
    }).catch(err => {
        dispatch(setError(err.response.data.data.message, err.response))
    });
}
