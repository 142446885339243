import {Pagination, Row, Col, List, Typography, Button, Space, Radio, BackTop, Alert} from 'antd';
import React, {useEffect, useRef, useState} from "react";
import {useParams, useHistory} from "react-router-dom"
import store from "../../Store";
import SearchResult from "./SearchResult";
import {getSearchFormData, getSearchResults} from "../../Store/Action/SearchAction/Thunk";
import {useDispatch, useSelector} from "react-redux";
import {setSearchResults} from "../../Store/Action/SearchAction";
import {search} from "../../requests";
import {URL_HASH} from "../../constants";
import {accountSelector} from "../../Store/Selectors/Main";

const { Paragraph } = Typography;

const Results = () => {
    const { id, page, pageSize } = useParams();
    const dispatch = useDispatch();
    const loaded = useRef(false);
    const [list, setList] = useState();
    const [total, setTotal] = useState();
    const history = useHistory()
    const searchData =  store.getState().searches.searchFormData;
    const merging = searchData.status === 'merging';
    const merged = useRef(0);
    const account = useSelector(accountSelector);
    let limit = 20;

    if (pageSize > 0) {
        limit = pageSize;
    }

    const loadPage = (p, size) => {
        loaded.current = false;
        dispatch(setSearchResults([]));
        history.push('/' + URL_HASH + '/results/' + id + '/' + p + '/' + size)
    }

    useEffect(() => {
        const unsub = store.subscribe(() => {
            const state = store.getState();

            setList(state.searches.results)
            setTotal(state.searches.totalResults)
        });

        if (!loaded.current) {
            loaded.current = true;
            dispatch(getSearchFormData(id))
            dispatch(getSearchResults(id, page > 0 ? page : 1, limit, merged.current))
        }

        return function cleanup() {
            unsub();
        }
    });

    const checkAll = () => {
        search().get('/check-all.json', {params: {id}}).then(() => loadPage(page, limit))
    }

    const uncheckAll = () => {
        search().get('/uncheck-all.json', {params: {id}}).then(() => loadPage(page, limit))
    }

    const merge = () => {
        search().get('/merge.json', {params: {id}}).then(() => history.push('/' + URL_HASH))
    }

    const offset = ((page > 0 ? page : 1) - 1) * limit;

    return (
        <>
            <h2>{searchData.name}</h2>
            <BackTop />
            <Row style={{marginBottom: 20}}>
                <Col span="24">
                    {account.widgetInfo.isTest && (
                        <Alert message="Пробный доступ" type="info" showIcon description={"Внимание! В тестовом режиме количество объединений ограничено. Осталось " + (100 - parseInt(account.widgetInfo.totalMerges || 0))} />
                    )}
                </Col>
            </Row>
            <Row>
                <Col span="24">
                    <blockquote className="primary">
                        <Typography>
                            <Paragraph>
                                Одна сущность может принадлежать к двум группам дублей (например по телефону к одной, по почте к другой).
                                В данном списке одна сущность принадлежит только к одной группе дублей чтобы было легче их просматривать.
                            </Paragraph>
                            <Paragraph>
                                После объединения и удаления дублей можно выполнить поиск заново с такими же настройками и найти оставшиеся группы
                                дублей. Операцию можно повторять до тех пор, пока не будет найдено ни одной группы дублей.
                            </Paragraph>
                        </Typography>
                    </blockquote>
                </Col>
            </Row>
            <Row>
                <Button.Group>
                    <Button disabled={merging} size="small" type="primary" onClick={merge}>Объединить отмеченные</Button>
                </Button.Group>
            </Row>
            <Row style={{marginTop: 20}}>
                <Space>
                    Показывать:
                    <Radio.Group value={merged.current} onChange={e => {merged.current = e.target.value; loadPage(1, limit)}}>
                        <Radio value={0}>Все</Radio>
                        <Radio value="merged">Только объединенные</Radio>
                        <Radio value="notMerged">Только необъединенные</Radio>
                    </Radio.Group>
                </Space>
            </Row>
            <Pagination
                style={{textAlign: 'right', marginBottom: 20}}
                total={total}
                onChange={loadPage}
                pageSize={limit}
                current={parseInt(page) ? parseInt(page) : 1}
                showSizeChanger={true}
            />
            <Row style={{marginBottom: 20}}>
                <Button.Group>
                    <Button disabled={merging} size="small" type="primary" onClick={checkAll}>Отметить все</Button>
                    <Button disabled={merging} size="small" type="primary" onClick={uncheckAll}>Отменить все</Button>
                </Button.Group>
            </Row>
            <Row>
                <Typography>Всего групп: <b>{total}</b></Typography>
            </Row>
            <Row>
                <Col>
                    <List itemLayout="vertical" size="large"
                          dataSource={list}
                          renderItem={(item, idx) => (
                              <SearchResult key={item.id} {...item} index={idx} offset={offset} merging={merging} />
                          )}
                    />
                </Col>
            </Row>
        </>
    )
}

export default Results
