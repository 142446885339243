import { combineReducers } from "redux";
import {SETWARNING, SETERROR, SETINFO, SETSUCCESS, SETACCOUNT, SETFIELDS} from "../ActionType/MainActionType";
import searchReducer from "./SearchesReducer"

const rootReducer = combineReducers({
    searches: searchReducer,
    error: function (state, action) {
        if (action.type === SETERROR) {
            state = {text: action.text, error: action.error};
        } else {
            state = {text: ''}
        }

        return state;
    },
    warning: function (state, action) {
        if (action.type === SETWARNING) {
            state = action.text;
        } else {
            state = ''
        }

        return state;
    },
    info: function (state, action) {
        if (action.type === SETINFO) {
            state = action.text;
        } else {
            state = ''
        }

        return state;
    },
    success: function (state, action) {
        if (action.type === SETSUCCESS) {
            state = action.text;
        } else {
            state = ''
        }

        return state;
    },
    account: function (state, action) {
        if (action.type === SETACCOUNT) {
            state = action.data;
        } else if (state === undefined) {
            state = {
                pipelines: [],
                users: [],
                widgetInfo: {}
            }
        }

        return state;
    },
    fields: function (state, action) {
        if (action.type === SETFIELDS) {
            state = action.data;
        } else if (state === undefined) {
            state = []
        }

        return state;
    }
});

export default rootReducer;
