import {Form, Input, Row, Col, Select, Space, Button, TreeSelect, Popconfirm, Alert, Switch, Collapse } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {useDispatch, useSelector, useStore} from "react-redux";
import {setError} from "../../Store/Action/MainAction";
import {createSearch, getSearchFormData} from "../../Store/Action/SearchAction/Thunk";
import React, {useEffect, useState} from "react";
import {useParams, Link} from "react-router-dom"
import {getFields} from "../../Store/Action/MainAction/Thunk";
import {formDataSelector} from "../../Store/Selectors/Search";
import {accountSelector, fieldsSelector} from "../../Store/Selectors/Main";
import {CRM, URL_HASH} from "../../constants";

const {Panel} = Collapse;

const SearchForm = () => {
    const store = useStore()
    const { Option } = Select;
    const { SHOW_PARENT } = TreeSelect;
    const dispatch = useDispatch();
    const { id } = useParams();
    const [form] = Form.useForm();
    const [popVisible, setPopVisible] = useState(false);
    const fields = useSelector(fieldsSelector),
        account = useSelector(accountSelector);
    const [switchChecked, setSwitchChecked] = useState(false);
    const [filterSwitchChecked, setFilterSwitchChecked] = useState(false);
    const [deleteSwitchChecked, setDeleteSwitchChecked] = useState(false);

    const popConfirm = () => form.submit();
    const visibleChange = visible => visible && !id ? popConfirm() : setPopVisible(visible);

    const onFinish = (values) => {
        dispatch(createSearch(values));
    };

    const onFinishFailed = (errorInfo) => {
        dispatch(setError('Заполните обязательные поля.', errorInfo));
    };

    useEffect(() => {
        dispatch(getFields('contacts'));

        if (id) {
            dispatch(getSearchFormData(id))
        }
    }, [dispatch, id]);

    useEffect(() => {
        const unsub = store.subscribe(() => {
            const state = store.getState(),
                formData = formDataSelector(state),
                fields = fieldsSelector(state)

            const defaultFormValues = {
                multipleFieldIds: fields.filter(field => ['PHONE', 'EMAIL'].includes(field.code)).map(field => field.id),
                entityType: 'contacts',
                deleteFieldId: 'create',
                autoMergeMode: 'update',
                crm: CRM,
                criteria: [
                    {}
                ]
            };

            form.setFieldsValue(id ? formData : defaultFormValues);

            if (id && switchChecked !== formData.autoMergeEnabled) {
                setSwitchChecked(formData.autoMergeEnabled);
            }

            if (id && deleteSwitchChecked !== formData.deleteDoubles) {
                setDeleteSwitchChecked(formData.deleteDoubles);
            }

            if (id && filterSwitchChecked !== formData.doublesWithoutFilter) {
                setFilterSwitchChecked(formData.doublesWithoutFilter);
            }
        })

        return function cleanup() {
            unsub();
        }
    }, [id, form, store, switchChecked, deleteSwitchChecked, fields])

    const treeProps = {
        treeData: account.pipelines.map((pipe) => ({
            title: pipe.name,
            value: pipe.id,
            key: pipe.id,
            color: pipe.color,
            children: pipe.statuses.map((status) => ({
                title: status.name,
                value: pipe.id + '-' + status.id,
                key: pipe.id + '-' + status.id,
                color: status.color
            }))
        })),
        treeCheckable: true,
        showCheckedStrategy: SHOW_PARENT,
        placeholder: 'Воронки и статусы',
    }

    const users = account.users.map((user) => ((
        <Option value={user.id} key={user.id}>{user.name}</Option>
    )))

    const deleteFieldOptions = fields.filter(field => field.type === 'checkbox' && field.is_api_only)
        .map(field => (
            <Option key={field.id} value={field.id}>{field.name}</Option>
        ))

    deleteFieldOptions.push((
        <Option key="create" value="create">Создать поле...</Option>
    ))

    const multipleFieldOptions = fields.filter(field => field.type === 'multiselect' || ['PHONE', 'EMAIL'].includes(field.code))
        .map(field => (
            <Option key={field.id} value={field.id}>{field.name}</Option>
        ))

    const span = {
        xl: 14,
        lg: 18,
        md: 24,
        sm: 24,
        xs: 24
    }

    return (
        <>
            <Row>
                {id > 0 &&
                <Alert style={{marginBottom: 20}} message="При редактировании загруженные данные удаляются и поиск запустится заново" type="warning" showIcon closable />
                }
            </Row>
            <Row id="search-form">
                <Col {...span}>
                    <Form
                        form={form}
                        name="search"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            name="id"
                            hidden
                        >
                            <Input type="hidden" />
                        </Form.Item>

                        <Form.Item
                            name="crm"
                            hidden
                        >
                            <Input type="hidden" />
                        </Form.Item>

                        <Form.Item
                            label="Название"
                            name="name"
                            rules={[{ required: true, message: 'Введите название' }]}
                            tooltip="Придумайте любое название"
                        >
                            <Input />
                        </Form.Item>

                        <Collapse style={{marginBottom: 24}}>
                            <Panel forceRender={true} header="Основные настройки" key="1">
                                <Form.Item
                                    label="Сущность для поиска"
                                    name="entityType"
                                    rules={[{ required: true, message: 'Выберите сущность' }]}
                                    tooltip="Поиск и объединение дублей будет именно по выбранной сущности"
                                >
                                    <Select>
                                        <Option value="contacts">Контакты</Option>
                                        <Option disabled value="companies" title="В разработке">Компании</Option>
                                        <Option disabled value="leads" title="В разработке">Сделки</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Поле для отметки"
                                    name="deleteFieldId"
                                    rules={[{ required: true, message: 'Выберите поле' }]}
                                    tooltip="В процессе объединения виджет помечает дубли в специальное поле с типом 'Флаг' т.к. нет возможности автоматически их удалить через API. Вы можете выбрать поле здесь или приказать создать новое поле. После объединения дубли можно будет быстро удалить."
                                >
                                    <Select>
                                        {deleteFieldOptions}
                                    </Select>
                                </Form.Item>
                            </Panel>
                            <Panel forceRender={true} key="2" header="Настройки объединения">
                                <Form.Item
                                    label="Главный в группе"
                                    name="autoMergeMode"
                                    rules={[{ required: true, message: 'Выберите вариант' }]}
                                    tooltip="По умолчанию главным дублем, в который будет происходить объединение, является 'самый старый' дубль. Вы можете выбрать более подходящий вариант."
                                >
                                    <Select>
                                        <Option value="create">Первый по дате создания</Option>
                                        <Option value="update">По последнему касанию</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Автообъединение"
                                    name="autoMergeEnabled"
                                    tooltip="Внимание! Пользоваться с осторожностью! По умолчанию вам самим надо указать те группы дублей, которые вы хотите объединить, также есть кнопка для объединения всех дублей. Если не хотите дожидаться окончания поиска, то можете поставить галочку и виджет сразу после поиска запустит процесс объединения."
                                >
                                    <Switch checked={switchChecked} onChange={setSwitchChecked} />
                                </Form.Item>

                                {
                                    CRM === 'b24' &&
                                    <Form.Item
                                        label="Удалять дубли сразу"
                                        name="deleteDoubles"
                                        tooltip="По умолчанию виджет отмечает дубли в специальное поле, после объединения вы можете удалить их в списке поисков. Или вы можете включить опцию и виджет удалит дубли сразу после объединения."
                                    >
                                        <Switch checked={deleteSwitchChecked} onChange={setDeleteSwitchChecked} />
                                    </Form.Item>
                                }

                                <Form.Item
                                    label="Множественные поля"
                                    name="multipleFieldIds"
                                    tooltip="По умолчанию виджет не объединяет поля, в которых есть несколько значений, кроме адресов почты и телефонов. Вы можете указать здесь те поля, для которых значения будут набираться в главном дубле из остальных, а также изменить настройку по умолчанию."
                                >
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        placeholder="Поля"
                                    >{multipleFieldOptions}</Select>
                                </Form.Item>
                            </Panel>

                            <Panel forceRender={true} key="3" header="Настройки фильтрации">
                                <Form.Item
                                    label="Искать дубли по всей базе"
                                    name="doublesWithoutFilter"
                                    tooltip="По умолчанию дубли тоже должны попадать под условия фильтра, указанного ниже. Эта опция отменяет условие."
                                >
                                    <Switch checked={filterSwitchChecked} onChange={setFilterSwitchChecked} />
                                </Form.Item>

                                <Form.Item
                                    label="Фильтр по ответственным"
                                    name="users"
                                    tooltip="Вы можете указать здесь только тех пользователей, по которым будет производиться поиск дублей. Это можно делать в случае, если вам, например, надо произвести поиск по базе, которую вы только что загрузили на определенного пользователя. Либо наоборот, исключить её."
                                >
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        placeholder="Пользователи"
                                    >{users}</Select>
                                </Form.Item>

                                <Form.Item
                                    label="Фильтр по статусам сделки"
                                    name="statuses"
                                    tooltip="Эта опция включает только те сущности, у которых есть хоть одна сделка в выбранных вами статусах."
                                >
                                    <TreeSelect {...treeProps} />
                                </Form.Item>
                            </Panel>
                        </Collapse>

                        <Col {...span}>
                            <Form.List name="criteria">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(field => (
                                            <Space key={field.key} align="baseline">
                                                <Form.Item
                                                    {...field}
                                                    label="Критерий"
                                                    name={[field.name, 'criterion']}
                                                    fieldKey={[field.fieldKey, 'criterion']}
                                                    rules={[{ required: true, message: 'Надо заполнить поле' }]}
                                                    style={{ width: 300 }}
                                                    tooltip="Выберите поле, значения которого виджет будет сравнивать для установления дублей"
                                                >
                                                    <Select>
                                                        <Option key="phone" value="phone">
                                                            Телефон
                                                        </Option>
                                                        <Option key="email" value="email">
                                                            E-mail
                                                        </Option>
                                                        <Option key="name" value="name" disabled title="В разработке">
                                                            Название
                                                        </Option>
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    label="Регулярка (для продв.)"
                                                    name={[field.name, 'filter']}
                                                    fieldKey={[field.fieldKey, 'filter']}
                                                    style={{ width: 500 }}
                                                    tooltip="Внимание! Только для продвинутых пользователей! Регулярное выражение в этом поле может либо валидировать значение (например для поиска по почтам Яндекса напишите /@yandex\.ru/i), либо отсекать лишнее и брать только нужное (для этого используйте группы захвата, например /^(.+)@(.+)\.ru$/). Проверить себя можно на https://regex101.com/"
                                                >
                                                    <Input
                                                        placeholder={'/(reg)ular\\s+[Ee]xpres{2,}ion/iu'}
                                                    />
                                                </Form.Item>

                                                {fields.length > 1 && (
                                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                                )}
                                            </Space>
                                        ))}

                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Добавить критерии
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Popconfirm
                                title="Если вы сохраните изменения, то загрузка начнется заново"
                                visible={popVisible}
                                onVisibleChange={visibleChange}
                                okText="Сохранить"
                                cancelText="Отменить"
                                onConfirm={popConfirm}
                            >
                                <Button type="primary" htmlType="submit">
                                    Сохранить
                                </Button>
                            </Popconfirm>
                            <Link to={'/' + URL_HASH}>
                                <Button type="default">
                                    Отменить
                                </Button>
                            </Link>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </>
    )
}

export default SearchForm;
