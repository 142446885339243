import React, {useState} from "react";
import {Modal, Typography, List} from "antd";
import {getLinkedLeads} from "../../Store/Action/SearchAction/Thunk";
import {useDispatch, useSelector} from "react-redux";
import {modalLeadsSelector} from "../../Store/Selectors/Search";
import {accountSelector} from "../../Store/Selectors/Main";
import { LinkOutlined } from '@ant-design/icons'
import {setModalLeads} from "../../Store/Action/SearchAction";
import {CRM} from "../../constants";

const {Link} = Typography

const LeadsModal = data => {
    const dispatch = useDispatch();
    const modalLeads = useSelector(modalLeadsSelector)
    const [modalVisible, setModalVisible] = useState(false)
    const account = useSelector(accountSelector)

    const getPipeline = pipelineId => {
        let found = null;

        account.pipelines.forEach(pipe => {
            if (pipe.id == pipelineId) {
                found = pipe;
            }
        })

        return found
    }

    const getStatus = (pipelineId, statusId) => {
        let found = null
        const pipe = getPipeline(pipelineId);

        pipe.statuses.forEach(status => {
            if (status.id === statusId) {
                found = status;
                found.pipeline = pipe
            }
        })

        if (!found) {
            found = {id: statusId, name: statusId, pipeline: pipe}
        }

        return found;
    }

    const showLeadsModal = () => {
        dispatch(getLinkedLeads(data.entity.id))

        setModalVisible(true)
    }

    const leadsInfo = () => {
        if (data.entity.linkedEntities === undefined || data.entity.linkedEntities.leads === undefined || data.entity.linkedEntities.leads.length === 0) {
            return '';
        }

        return (
            <Link onClick={showLeadsModal}>Сделок: {data.entity.linkedEntities.leads.length}</Link>
        )
    }

    return (
        <>
            {leadsInfo()}
            <Modal width={700} title="Связанные сделки" visible={modalVisible} onOk={() => setModalVisible(false)} onCancel={() => {setModalVisible(false); dispatch(setModalLeads([]));}} cancelButtonProps={{hidden: true}}>
                <List
                    dataSource={modalLeads}
                    bordered
                    renderItem={lead => {
                        let status = {color: '#fff', pipeline: {name: ''}, name: ''};

                        if (CRM === 'amo') {
                            status = getStatus(lead.pipeline_id, lead.status_id)

                            return (
                                <List.Item>
                                    <Typography.Text>{status.pipeline.name}</Typography.Text>
                                    <Typography.Text style={{color: status.color}}>{status.name}</Typography.Text>
                                    <Link
                                        href={'https://' + account.subdomain + '.amocrm.ru/leads/detail/' + lead.id}
                                        target="_blank"
                                    >
                                        <LinkOutlined/>
                                        {lead.name}
                                    </Link>
                                </List.Item>
                            )
                        } else {
                            status = getStatus(lead.CATEGORY_ID, lead.STAGE_ID)

                            return (
                                <List.Item>
                                    <Typography.Text>{status.pipeline.name}:{status.name}</Typography.Text>
                                    <Link
                                        href={'https://' + account.subdomain + '/crm/deal/details/' + lead.ID + '/'}
                                        target="_blank"
                                    >
                                        <LinkOutlined/>
                                        {lead.TITLE}
                                    </Link>
                                </List.Item>
                            )
                        }
                    }}
                />
            </Modal>
        </>
    )
}

export default LeadsModal
