import React, {useState} from 'react'
import {Card, List, Radio, Button as AButton, Tooltip, Typography, Space} from 'antd'
import Button from 'antd-button-color'
import Double from "./Double";
import { CheckOutlined } from '@ant-design/icons'
import {search} from "../../requests";

const {Text} = Typography;

const SearchResult = (data) => {
    let allItems = {},
        boldItems = [],
        radioValue = 0;

    const [needMerge, setNeedMerge] = useState(!!data.needMerge)
    const [merging, setMerging] = useState(data.merging)
    const [merged, setMerged] = useState(data.status === 'merged');
    const [error, setError] = useState(data.status === 'error');

    data.doubles.forEach(item => {
        if (item.isMain) {
            radioValue = item.id;
        }

        Object.keys(item.entity.criteria).forEach(name => {
            item.entity.criteria[name].forEach(criterion => {
                criterion = criterion.toLowerCase()

                if (allItems[criterion] === undefined) {
                    allItems[criterion] = item.id
                } else if (allItems[criterion] !== item.id) {
                    boldItems.push(criterion)
                }
            })
        })
    })

    const markMerge = () => {
        search().get('/mark-to-merge.json', {params: {id: data.doubles[0].groupId, value: needMerge ? '' : 1}})
        setNeedMerge(!needMerge)
    }

    const mergeNow = () => {
        setNeedMerge(true)
        setMerging(true)

        search().get('/merge-now.json', {params: {id: data.doubles[0].groupId}})
            .then(res => {
                data.doubles.forEach((double) => {
                    if (double.id === res.data.data.mainId) {
                        double.isMain = true;
                    } else {
                        double.isMain = false;
                    }
                })

                setMerged(true)
            }).catch(e => setError(true))
    }

    return (
        <Card>
            {error &&
                <Tooltip title="Объединение не было завершено успешно. Возможно некоторые сущности отсутствуют в CRM. Проверьте их наличие, перейдя по ссылкам ниже. Если ничего не помогает, обратитесь к разработчику.">
                    <Text type="danger"><b>Ошибка!</b></Text>
                </Tooltip>
            }
            {merged &&
                <Tooltip title="Объединение успешно завершено. Результат объединения выделен жирным шрифтом.">
                    <Text type="success"><b>Успех!</b></Text>
                </Tooltip>
            }
            {!merged &&
                <AButton.Group style={{marginBottom: 10}}>
                    <Button disabled={merging || error} type={needMerge ? 'success' : 'default'} size="small" onClick={markMerge}>
                        К объединению
                        {needMerge &&
                        <CheckOutlined />
                        }
                    </Button>
                    <Button loading={merging && needMerge} disabled={merging} type="default" size="small" onClick={mergeNow}>
                        {merging && needMerge ? 'Объединение' : 'Объединить сейчас'}
                    </Button>
                </AButton.Group>
            }
            <Space align="start">
                <b>
                    {data.index + 1 + data.offset}.
                </b>
                <Radio.Group defaultValue={radioValue}>
                    <List itemLayout="vertical" size="small"
                          dataSource={data.doubles}
                          renderItem={item => (
                              <Double key={item.id} {...item} boldItems={boldItems} merged={merged} />
                          )}
                    />
                </Radio.Group>
            </Space>
        </Card>
    )
}

export default SearchResult
