import { Layout, Menu, message } from 'antd';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Searches from "./components/Searches/Searches";
import Settings from "./components/Settings/Settings";
import Results from "./components/Searches/Results";
import SearchForm from "./components/SearchForm/SearchForm";
import store from "./Store";
import {useDispatch} from "react-redux";
import {setError, setInfo, setSuccess, setWarning} from "./Store/Action/MainAction";
import {useEffect} from "react";
import {getAccount} from "./Store/Action/MainAction/Thunk";
import {URL_HASH} from "./constants";

function App() {
  const { Header, Content, Footer } = Layout;
  const dispatch = useDispatch();

  store.subscribe(() => {
    const state = store.getState();

    if (state.error.text !== '') {
      console.log(state.error.error);
      dispatch(setError(''));
      message.error(state.error.text);
    }

    if (state.info !== '') {
      dispatch(setInfo(''));
      message.info(state.info);
    }

    if (state.success !== '') {
      dispatch(setSuccess(''));
      message.success(state.success);
    }

    if (state.warning !== '') {
      dispatch(setWarning(''));
      message.warning(state.warning);
    }

    return true;
  })

  useEffect(() => {
    dispatch(getAccount());
  })

  return (
    <Router basename={process.env.REACT_APP_BASE_URL}>
      <Layout className="layout">
        <Header>
          <div className="logo" />
          <Menu theme="light" mode="horizontal" defaultSelectedKeys={['1']}>
            <Menu.Item key="1">
              <Link to={'/' + URL_HASH}>Поиск дублей</Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Content style={{padding: '0 50px'}}>
          <div className="site-layout-content" style={{background: 'white', padding: '10px'}}>
            <Switch>
              <Route path="/hash-:hash/settings">
                <h2>Настройки</h2>
                <Settings/>
              </Route>
              <Route path="/hash-:hash/add">
                <h2>Добавить поиск</h2>
                <SearchForm/>
              </Route>
              <Route path="/hash-:hash/edit/:id">
                <h2>Редактировать поиск</h2>
                <SearchForm/>
              </Route>
              <Route path="/hash-:hash/results/:id/:page/:pageSize">
                <Results/>
              </Route>
              <Route path="/hash-:hash/results/:id">
                <Results/>
              </Route>
              <Route path="/hash-:hash">
                <h2>Поиск дублей</h2>
                <Searches/>
              </Route>
            </Switch>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>iTrack ©2021</Footer>
      </Layout>
    </Router>
  );
}

export default App;
