import React, {useState} from "react";
import {Button, Popconfirm} from "antd";
import {LoadingOutlined} from '@ant-design/icons';
import {search} from "../../requests";
import {useDispatch} from "react-redux";
import {setError, setSuccess} from "../../Store/Action/MainAction";

const DeleteDoublesLink = (props) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();

    const deleteDoubles = () => {
        if (loading) {
            return;
        }

        setLoading(true)

        search().get('/delete-all-doubles.json', {params: {id: props.search.id}})
            .then(() => {
                dispatch(setSuccess('Дубли удалены!'))
                setLoading(false)
            })
            .catch(() => {
                dispatch(setError('Ошибка!'))
                setLoading(false)
            })
    }

    return (
        <>
            <Popconfirm
                title="Вы действительно хотите удалить все неудаленные дубли в CRM? Операция необратима"
                okText="Да"
                cancelText="Отмена"
                onConfirm={deleteDoubles}
            >
                <Button type="link" danger>
                    {loading &&
                        <LoadingOutlined />
                    }
                    Удалить дубли в CRM
                </Button>
            </Popconfirm>
        </>
    )
}

export default DeleteDoublesLink;
