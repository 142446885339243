import React from "react";
import {Row, Col, Form} from "antd";

const Settings = () => {
    const [form] = Form.useForm();

    const onFinish = values => {

    }

    const onFinishFailed = () => {

    }

    return (
        <Row>
            <Col>
                <Form
                    name="search"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}
                >

                </Form>
            </Col>
        </Row>
    )
}

export default Settings;
