import React, {useState} from 'react'
import {Card, Tag, Row, Col, Switch, Typography, Space, Tooltip, Button, Popconfirm} from "antd"
import { EditOutlined, CloudDownloadOutlined, LoadingOutlined, BranchesOutlined, SearchOutlined, TeamOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom";
import {useDispatch, useStore} from "react-redux";
import {setError} from "../../Store/Action/MainAction";
import {search} from "../../requests";
import {CRM, URL_HASH} from "../../constants";
import DeleteDoublesLink from "./DeleteDoublesLink";

const { Text } = Typography;

const SearchItem = (props) => {
    let searchItem = props;
    const dispatch = useDispatch();
    const [switchLoading, setSwitchLoading] = useState(false);
    const [active, setActive] = useState(searchItem.active);
    const [deleted, setDeleted] = useState(false);
    const store = useStore();

    const statuses = {
        "new": {
            title: 'Новый',
            hint: 'Скоро начнется загрузка'
        },
        loading: {
            title: 'Загрузка',
            hint: 'Скачиваются и фильтруются данные из CRM'
        },
        indexing: {
            title: 'Индексация',
            hint: 'Данные загружаются в поисковую базу'
        },
        searching: {
            title: 'Поиск дублей',
            hint: 'Производится поиск и формируются группы дублей'
        },
        ready: {
            title: 'Готов',
            hint: 'Поиск дублей завершен. Можно смотреть результаты'
        },
        merging: {
            title: 'Объединение',
            hint: 'Производится объединение групп дублей'
        }
    };

    const onSwitch = () => {
        // setSwitchLoading(true)

        search().get('/set-active.json', {params: {id: searchItem.id, active: +!active}})
            .then(() => {
                setActive(!active)
                setSwitchLoading(false)
            }).catch(err => {
                setSwitchLoading(false)
                dispatch(setError(err.response.data.data.message, err.response))
            })
    };

    const isInWork = () => {
        return !!(searchItem.status !== 'ready' && active);
    };

    const deleteSearch = () => {
        setDeleted(true)
        search().get('/delete.json', {params: {id: searchItem.id}})
    }

    return (
        <Card hidden={deleted}>
            <Row gutter={[0, 10]}>
                <Col span="18">
                    <h3>
                        <Space>
                            {searchItem.name}
                            <Tooltip title={statuses[searchItem.status].hint}>
                                <Tag color="green">{statuses[searchItem.status].title}</Tag>
                            </Tooltip>
                            {isInWork() &&
                            <LoadingOutlined />
                            }
                        </Space>
                    </h3>
                </Col>
                <Col span="6">
                    <div style={{float: "right"}}>
                        <Space align="center">
                            <Link to={'/' + URL_HASH + '/edit/' + searchItem.id}>
                                <EditOutlined style={{fontSize: 20}} />
                            </Link>
                            <Tooltip title={active ? 'Приостановить' : 'Возобновить'}>
                                <Button size="small" type={active ? 'success' : 'dashed'} onClick={onSwitch} loading={switchLoading}>{active ? 'Активен' : 'Остановлен'}</Button>
                            </Tooltip>
                            <Button type="link" danger>
                                <Popconfirm
                                    title="Удалить поиск и все данные по нему?"
                                    okText="Удалить"
                                    cancelText="Отмена"
                                    onConfirm={deleteSearch}
                                >
                                    Удалить
                                </Popconfirm>
                            </Button>
                        </Space>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span="24">
                    <Space>
                        <Tooltip title="Загружено">
                            <Text type="secondary">
                                <CloudDownloadOutlined /> {searchItem.stat.downloaded}
                            </Text>
                        </Tooltip>
                        <Tooltip title="Проиндексировано">
                            <Text type="secondary" title="Проиндексировано">
                                <BranchesOutlined /> {searchItem.stat.indexed}
                            </Text>
                        </Tooltip>
                        <Tooltip title="Обработано поиском">
                            <Text type="secondary" title="Обработано поиском">
                                <SearchOutlined /> {searchItem.stat.searched}
                            </Text>
                        </Tooltip>
                        {['merging', 'ready'].includes(searchItem.status) &&
                            <>
                                <Tooltip title="Объединено / Найдено групп дублей">
                                    <Text type="secondary" title="Обработано поиском">
                                        <TeamOutlined /> {searchItem.stat.mergedGroupsCount} / {searchItem.stat.groupsCount}
                                    </Text>
                                </Tooltip>
                                {searchItem.status === 'ready' &&
                                    <Link to={'/' + URL_HASH + '/results/' + searchItem.id + '/20'}>
                                        Смотреть результаты
                                    </Link>
                                }
                                {(searchItem.status === 'ready' && searchItem.stat.mergedGroupsCount > 0 && CRM === 'amo') &&
                                    <Button type="link" href={'https://' + store.getState().account.subdomain + '.amocrm.ru/contacts/list/' + searchItem.entityType + '/?filter[cf][' + searchItem.deleteFieldId + ']=Y&useFilter=y'} target="_blank" danger>
                                        Удалить дубли в CRM
                                    </Button>
                                }
                                {
                                    (searchItem.status === 'ready' && searchItem.stat.hasNotDeletedDoubles && CRM === 'b24') &&
                                    <DeleteDoublesLink search={searchItem}/>
                                }
                            </>
                        }
                    </Space>
                    <div style={{float: "right"}}>
                        <Text type="secondary">Дата создания: {new Date(searchItem.createdAt * 1000).toLocaleString()}</Text>
                    </div>
                </Col>
            </Row>
        </Card>
    )
}

export default SearchItem;
