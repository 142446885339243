import { Button, Row, Col, List } from 'antd'
import React, {useEffect} from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getSearches} from "../../Store/Action/SearchAction/Thunk";
import SearchItem from "./SearchItem";
import {searchesSelector} from "../../Store/Selectors/Search";
import {URL_HASH} from "../../constants";

const Searches = () => {
    const dispatch = useDispatch();
    const searches = useSelector(searchesSelector)

    useEffect(() => {
        dispatch(getSearches());

        const timerId = setInterval(() => {
            dispatch(getSearches());
        }, 3000);

        return function cleanup() {
            clearInterval(timerId)
        }
    }, [dispatch]);

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Link to={'/' + URL_HASH + '/add'}>
                        <Button type="primary" icon={<PlusOutlined />}>Добавить поиск</Button>
                    </Link>
                </Col>
            </Row>
            <List itemLayout="vertical" size="large"
                dataSource={searches}
                renderItem={item => (
                    <SearchItem key={item.id} {...item} />
                )}
            />
        </React.Fragment>
    )
}

export default Searches;
